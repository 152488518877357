import { CSSProperties, ReactNode } from 'react';
import ReactModal from 'react-modal';
import { useTheme } from 'src/ui-kit/theme';
import { disableBodyScroll, enableBodyScroll } from 'src/utils/system/scrollLock';
import LoadingBarIndicator from '../LoadingBarIndicator/LoadingBarIndicator';
import GeneralModalCloseButton from './GeneralModalCloseButton/GeneralModalCloseButton';

ReactModal.defaultStyles.content = {};

export interface Props {
  children: ReactNode;
  isOpen: boolean;
  onClose(): void;
  contentStyles?: CSSProperties;
  overlayStyles?: CSSProperties;
  withCloseButton?: boolean;
  isFetching?: boolean;
  isCentered?: boolean;
}

export default function GeneralModal(props: Props) {
  const {
    children,
    onClose,
    isOpen,
    overlayStyles,
    contentStyles,
    withCloseButton = true,
    isFetching,
    isCentered = false,
  } = props;

  const theme = useTheme();

  const centeredStyles: CSSProperties = isCentered ? {
    position: 'absolute',
    margin: '0',
    left: '50%',
    top: 'calc(50% - 15px)',
    transform: 'translate(-50%, -50%)',
  } : {};

  const defaultStyles: {
    overlay: CSSProperties;
    content: CSSProperties;
  } = {
    overlay: {
      background: 'rgba(51,51,51,0.45)',
      zIndex: theme.base.zIndex.modalOverlay,
      display: 'flex',
      overflowY: 'auto',
      textAlign: 'center',
    },
    content: {
      border: 'none',
      background: 'none',
      borderRadius: 0,
      padding: 0,
      outline: 'none',
      margin: '50px auto auto',
      maxWidth: '100%',
      display: 'inline-block',
      position: 'relative',
      textAlign: 'initial',
      verticalAlign: 'middle',
      ...centeredStyles,
    },
  };

  const style: ReactModal.Props['style'] = {
    overlay: {
      ...(overlayStyles || defaultStyles.overlay),
      lineHeight: '100vh',
    },
    content: {
      ...(contentStyles || defaultStyles.content),
      lineHeight: 'initial',
    },
  };

  if (isOpen && typeof window === 'undefined') {
    throw new Error('Do not render modals on SSR.');
  }

  return (
    <ReactModal
      onRequestClose={onClose}
      shouldCloseOnEsc
      shouldReturnFocusAfterClose
      isOpen={isOpen}
      style={style}
      bodyOpenClassName='Modal_opened'
      onAfterOpen={disableBodyScroll}
      onAfterClose={enableBodyScroll}
      ariaHideApp={false}
    >
      {children}

      {withCloseButton && <GeneralModalCloseButton onClick={onClose} />}

      {isFetching && <div className='Modal__loader'><LoadingBarIndicator /></div>}

      <style jsx>{`
        .Modal__loader {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          background-color: ${theme.base.color.white};
          opacity: 0.8;
          z-index: 1;
        }
      `}</style>
    </ReactModal>
  );
}
