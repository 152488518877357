import { ReactNode } from 'react';
import StylishBox from '../StylishBox/StylishBox';
import { StylishBoxResponsiveProps, StylishBoxStyles } from '../StylishBox/types';
import { useTheme } from '../theme';
import { Theme } from '../theme/types';
import { getGapSBS } from './helpers';

export type GapProps = StylishBoxResponsiveProps<keyof Theme['base']['space']>;

interface StackProps {
  element?: React.HTMLElementType;
  children: ReactNode;
  flexDirection?: StylishBoxStyles['flexDirection'];
  justifyContent?: StylishBoxStyles['justifyContent'];
  gap?: GapProps;
  alignItems?: StylishBoxStyles['alignItems'];
  wrapped?: boolean;
  sbs?: StylishBoxStyles;
  className?: string;
  dataMarker?: string;
}

const Stack = ({
  element = 'div',
  children,
  flexDirection = 'row',
  justifyContent = 'flex-start',
  alignItems,
  gap: gapProps,
  sbs,
  wrapped = false,
  className,
  dataMarker,
}: StackProps) => {

  const theme = useTheme();
  const flexWrap = wrapped? 'wrap' : 'nowrap';
  const gap = getGapSBS(gapProps, theme.base.space);

  return (
    <StylishBox
      element={element}
      sbs={{
        flexDirection,
        justifyContent,
        alignItems,
        flexWrap,
        flexGrow: '1',
        gap,
        ...sbs,
        display: 'flex',
      }}
      className={className}
      dataMarker={dataMarker}
    >
      {children}
    </StylishBox>
  );
};

export default Stack;
