import styled from '@emotion/styled';
import typography from 'src/styles/typography';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const AccountNavigationStyled = styled.div<StyledThemeProps>(({ theme }) => `
  & .AccountNavigation__user {
    padding: 0 16px;
    ${typography.text};
    line-height: 24px;
  }
  & .AccountNavigation__userName {
    font-weight: 600;
  }
  & .AccountNavigation__line {
    height: 1px;
    width: 100%;
    margin-top: 4px;
    background: ${theme.base.color.gray200};
  }
  & .AccountNavigation__link {
    background: transparent;
    border: none;
    outline: none;
    position: relative;
    display: flex;
    align-items: baseline;
    align-self: flex-start;
    padding: 0;
    width: 100%;
    text-decoration: none;
    cursor: pointer;
  }
  & .AccountNavigation__link:hover {
    background: ${theme.base.color.gray100};
  }
  &.AccountNavigation_account .AccountNavigation__link_active:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background-color: ${theme.base.color.primary};
    transition: 0.2s all ease-in-out;
  }
  &.AccountNavigation_account .AccountNavigation__logout {
    margin-top: 12px;
  }
  &.AccountNavigation_account .AccountNavigation__line + .AccountNavigation__logout {
    margin-top: 0;
  }
`);
